function getCorsHeaders(event) {
  let allowOrigin = '';

  const requestOrigin = event?.headers?.origin || '';
  console.log('requestOrigin', requestOrigin);

  if (requestOrigin === 'https://cdn.shopify.com') {
    allowOrigin = requestOrigin;
  }
  if (
    requestOrigin.includes('.shopifycdn.com') ||
    requestOrigin.includes('.ankermake.com') ||
    requestOrigin.includes('.eufymake.com') ||
    requestOrigin.includes('.seenebula.com') ||
    requestOrigin.includes('.ankerwork.com') ||
    requestOrigin.includes('.anker.com') ||
    requestOrigin.includes('.eufy.com') ||
    requestOrigin.includes('.soundcore.com') ||
    requestOrigin.includes('localhost')
  ) {
    allowOrigin = requestOrigin;
  }

  if (!allowOrigin) {
    return {};
  }

  return {
    'Access-Control-Allow-Origin': allowOrigin,
    'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, DELETE',
    'Access-Control-Allow-Headers': 'current-language',
    'Access-Control-Allow-Credentials': 'true',
  };
}

module.exports = {
  getCorsHeaders,
};
