const { prodEufyEuApps } = require('./eufyEu');

const usProd = [
  {
    app: 'ankerus',
    domain: 'https://www.anker.com',
    brand: 'anker',
    country: 'us',
    lang: 'en',
  },
  {
    app: 'ankerwork-usa',
    domain: 'https://us.ankerwork.com',
    brand: 'ankerwork',
    country: 'us',
    lang: 'en',
  },
  {
    app: 'ankermake-us',
    appAlias: ['ankermake'],
    domain: 'https://www.ankermake.com',
    brand: 'ankermake',
    country: 'us',
    lang: 'en',
  },
  {
    app: 'soundcoreusa',
    domain: 'https://www.soundcore.com',
    brand: 'soundcore',
    country: 'us',
    lang: 'en',
  },
  {
    app: 'eufy-us',
    domain: 'https://www.eufy.com',
    brand: 'eufy',
    country: 'us',
    lang: 'en',
  },
  {
    app: 'nebula-us',
    domain: 'https://www.seenebula.com',
    brand: 'nebula',
    country: 'us',
    lang: 'en',
  },
  {
    app: 'mach-us',
    shopifyDomain: '495a46.myshopify.com',
    domain: 'https://www.mach.tech',
    brand: 'mach',
    country: 'us',
    lang: 'en',
  },
  // ankermake 相关，只提供了注册/激活
  {
    app: 'makeitreal',
    domain: 'https://makeitreal.ankermake.com/',
    brand: 'ankermake',
    country: 'us',
    lang: 'en',
    privacyPolicy: 'https://public-make-moat-us.s3.us-east-2.amazonaws.com/overall/AnkerMake-privacy.en.html',
    termsOfService: 'https://public-make-moat-us.s3.us-east-2.amazonaws.com/overall/AnkerMake-terms-of-service.en.html',
    type: 'app',
  },
  {
    app: 'ankersolix-us',
    domain: 'https://www.ankersolix.com',
    brand: 'ankersolix',
    country: 'us',
    lang: 'en',
  },
];

const neuProd = [
  {
    app: 'ankerneu',
    domain: 'https://www.ankernordics.com',
    brand: 'anker',
    country: 'neu',
    lang: 'en',
  },
];

const ukProd = [
  {
    app: 'ankeruk',
    domain: 'https://www.anker.com/uk',
    brand: 'anker',
    country: 'uk',
    lang: 'en',
  },
  {
    app: 'ankerwork-uk',
    domain: 'https://uk.ankerwork.com',
    brand: 'ankerwork',
    country: 'uk',
    lang: 'en',
  },
  {
    app: 'soundcoreuk',
    domain: 'https://www.soundcore.com/uk',
    brand: 'soundcore',
    country: 'uk',
    lang: 'en',
  },
  {
    app: 'eufy-uk',
    domain: 'https://www.eufy.com/uk',
    brand: 'eufy',
    country: 'uk',
    lang: 'en',
  },
  {
    app: 'nebula-gb',
    domain: 'https://www.seenebula.com/uk',
    brand: 'nebula',
    country: 'uk',
    lang: 'en',
  },
  {
    app: 'ankermake-uk',
    domain: 'https://www.ankermake.com/uk',
    brand: 'ankermake',
    country: 'uk',
    lang: 'en',
  },
];

const caProd = [
  {
    app: 'soundcoreca',
    domain: 'https://www.soundcore.com/ca',
    brand: 'soundcore',
    country: 'ca',
    lang: 'en',
  },
  {
    app: 'eufy-ca',
    domain: 'https://www.eufy.com/ca',
    brand: 'eufy',
    country: 'ca',
    lang: 'en',
  },
  {
    app: 'ankerca',
    domain: 'https://www.anker.com/ca',
    brand: 'anker',
    country: 'ca',
    lang: 'en',
  },
  {
    app: 'nebula-ca',
    domain: 'https://www.seenebula.com/ca',
    brand: 'nebula',
    country: 'ca',
    lang: 'en',
  },
  {
    app: 'ankermake-ca',
    domain: 'https://www.ankermake.com/ca',
    brand: 'ankermake',
    country: 'ca',
    lang: 'en',
  },
  {
    app: 'ankerwork-ca',
    domain: 'https://ankerwork-ca.myshopify.com',
    brand: 'ankerwork',
    country: 'ca',
    lang: 'en',
  },
  {
    app: 'ankersolix-ca',
    domain: 'https://www.ankersolix.com/ca',
    brand: 'ankersolix',
    country: 'ca',
    lang: 'en',
  },
];

const euProd = [
  {
    app: 'soundcoreeu',
    domain: 'https://www.soundcore.com/eu',
    brand: 'soundcore',
    country: 'eu',
    lang: 'en',
  },
  {
    app: 'eufy-eu',
    domain: 'https://www.eufy.com/eu-en',
    brand: 'eufy',
    country: 'eu-en',
    lang: 'en',
  },
  {
    app: 'ankereu',
    domain: 'https://www.anker.com/eu-en',
    appAlias: ['anker-eu'],
    brand: 'anker',
    country: 'eu-en',
    lang: 'en',
  },
  {
    app: 'ankermake-eur',
    domain: 'https://www.ankermake.com/eu-en',
    brand: 'ankermake',
    country: 'eu-en',
    lang: 'en',
  },
  {
    app: 'nebula-eu',
    domain: 'https://www.seenebula.com/eu',
    brand: 'nebula',
    country: 'eu-en',
    lang: 'en',
  },
];

const deProd = [
  {
    app: 'ankerde',
    domain: 'https://www.anker.com/eu-de',
    brand: 'anker',
    country: 'eu-de',
    lang: 'de',
  },
  {
    app: 'ankerwork-de',
    domain: 'https://de.ankerwork.com',
    brand: 'ankerwork',
    country: 'eu-de',
    lang: 'de',
  },
  {
    app: 'soundcorede',
    domain: 'https://www.soundcore.com/de',
    brand: 'soundcore',
    country: 'de',
    lang: 'de',
  },
  {
    app: 'eufy-de',
    domain: 'https://www.eufy.com/eu-de',
    brand: 'eufy',
    country: 'eu-de',
    lang: 'de',
  },
  {
    app: 'nebula-de',
    domain: 'https://www.seenebula.com/de',
    brand: 'nebula',
    country: 'eu-de',
    lang: 'de',
  },
  {
    app: 'ankermake-de',
    domain: 'https://www.ankermake.com/eu-de',
    brand: 'ankermake',
    country: 'eu-de',
    lang: 'de',
  },
  {
    app: 'mach-de',
    domain: 'https://www.mach.tech/eu-de',
    brand: 'mach',
    country: 'eu-de',
    lang: 'de',
  },
  {
    app: 'ankersolix-de',
    domain: 'https://ankersolix-de.myshopify.com',
    brand: 'ankersolix',
    country: 'de',
    lang: 'de',
  },
];

const esProd = [
  {
    app: 'eufy-es',
    domain: 'https://www.eufy.com/eu-es',
    brand: 'eufy',
    country: 'eu-es',
    lang: 'es',
  },
];

const frProd = [
  {
    app: 'soundcorefr',
    domain: 'https://www.soundcore.com/fr',
    brand: 'soundcore',
    country: 'fr',
    lang: 'fr',
  },
  {
    app: 'ankerfra',
    domain: 'https://www.anker.com/fr',
    brand: 'anker',
    country: 'fr',
    lang: 'fr',
  },
  {
    app: 'eufy-fr',
    domain: 'https://www.eufy.com/eu-fr',
    brand: 'eufy',
    country: 'eu-fr',
    lang: 'fr',
  },
  {
    app: 'ankersolix-fra',
    domain: 'https://www.ankersolix.com/fr',
    brand: 'ankersolix',
    country: 'fr',
    lang: 'fr',
  },
];

/** 生产环境荷兰语配置 */
const nlProd = [
  {
    app: 'eufy-nl',
    domain: 'https://www.eufy.com/nl',
    brand: 'eufy',
    country: 'nl',
    lang: 'nl',
  },
];

const auProd = [
  {
    app: 'ankerau',
    domain: 'https://www.anker.com/au',
    brand: 'anker',
    country: 'au',
    lang: 'en',
  },
  {
    app: 'au-soundcore',
    domain: 'https://www.soundcore.com/au',
    brand: 'soundcore',
    country: 'au',
    lang: 'en',
  },
  {
    app: 'eufy-au',
    domain: 'https://www.eufy.com/au',
    brand: 'eufy',
    country: 'au',
    lang: 'en',
  },
  {
    app: 'au-ankermake',
    shopifyDomain: 'ankermake-explore-the-3d-printing-frontier.myshopify.com',
    domain: 'https://www.ankermake.com/au',
    brand: 'ankermake',
    country: 'au',
    lang: 'en',
  },
];

const jpProd = [
  {
    app: 'anker-jp',
    domain: '',
    brand: 'anker',
    country: 'jp',
    lang: 'jp',
  },
  {
    app: 'eufy-jp',
    domain: '',
    brand: 'eufy',
    country: 'jp',
    lang: 'jp',
  },
  {
    app: 'nebula-jp',
    domain: '',
    brand: 'nebula',
    country: 'jp',
    lang: 'jp',
  },
  {
    app: 'soundcore-jp',
    domain: '',
    brand: 'soundcore',
    country: 'jp',
    lang: 'jp',
  },
  {
    app: 'ankerwork-jp',
    domain: '',
    brand: 'ankerwork',
    country: 'jp',
    lang: 'jp',
  },
  {
    app: 'ankermake-jp',
    domain: '',
    brand: 'ankermake',
    country: 'jp',
    lang: 'jp',
  },
  {
    app: 'mach-jp',
    domain: '',
    brand: 'mach',
    country: 'jp',
    lang: 'jp',
  },
  // ankermake 相关，只提供了注册/激活
  {
    app: 'makeitreal-jp',
    domain: 'https://makeitreal.ankermake.com/',
    brand: 'ankermake',
    country: 'jp',
    lang: 'jp',
    privacyPolicy: 'https://public-make-moat-us.s3.us-east-2.amazonaws.com/overall/AnkerMake-privacy.ja.html',
    termsOfService: 'https://public-make-moat-us.s3.us-east-2.amazonaws.com/overall/AnkerMake-terms-of-service.ja.html',
    type: 'app',
  },
];

const itProd = [
  {
    app: 'eufy-it',
    domain: 'https://www.eufy.com/eu-it',
    brand: 'eufy',
    country: 'eu-it',
    lang: 'it',
  },
  {
    app: 'ankersolix-it',
    domain: 'https://www.ankersolix.com/it',
    shopifyDomain: 'ad2548-00.myshopify.com',
    brand: 'ankersolix',
    country: 'it',
    lang: 'it',
  },
];

const clProd = [
  {
    app: 'soundcorecl',
    domain: 'https://cl.soundcore.com',
    brand: 'soundcore',
    country: 'cl',
    lang: 'es',
  },
];

const appProd = [
  {
    app: 'eufy-app',
    dtcApp: 'eufy-us',
    domain: 'https://www.eufy.com',
    shopifyDomain: 'eufy-us.myshopify.com',
    brand: 'eufy',
    country: 'app',
    lang: 'en',
    type: 'app',
  },
  {
    app: 'eufy-app-eu',
    dtcApp: 'eufy-eu',
    domain: 'https://www.eufy.com/eu-en',
    shopifyDomain: 'eufy-eu.myshopify.com',
    brand: 'eufy',
    country: 'app-eu',
    lang: 'en',
    type: 'app',
  },
  {
    app: 'soundcore-app',
    dtcApp: 'soundcoreusa',
    domain: 'https://www.soundcore.com',
    shopifyDomain: 'soundcoreusa.myshopify.com',
    brand: 'soundcore',
    country: 'app',
    lang: 'en',
    type: 'app',
  },
  {
    app: 'soundcore-app-eu',
    dtcApp: 'soundcoreeu',
    domain: 'https://www.soundcore.com/eu',
    shopifyDomain: 'soundcoreeu.myshopify.com',
    brand: 'soundcore',
    country: 'app-eu',
    lang: 'en',
    type: 'app',
  },
  {
    app: 'anker-app',
    dtcApp: 'ankerus',
    domain: 'https://www.anker.com',
    shopifyDomain: 'ankerus.myshopify.com',
    brand: 'anker',
    country: 'app',
    lang: 'en',
    type: 'app',
  },
  {
    app: 'anker-app-eu',
    dtcApp: 'ankereu',
    domain: 'https://www.anker.com/eu-en',
    shopifyDomain: 'ankereu.myshopify.com',
    brand: 'anker',
    country: 'app-eu',
    lang: 'en',
    type: 'app',
  },
  {
    app: 'nebula-app',
    dtcApp: 'nebula-us',
    domain: 'https://www.seenebula.com',
    shopifyDomain: 'nebula-us.myshopify.com',
    brand: 'nebula',
    country: 'app',
    lang: 'en',
    type: 'app',
  },
  {
    app: 'nebula-app-eu',
    dtcApp: 'nebula-eu',
    domain: 'https://www.seenbula.com/eu',
    shopifyDomain: 'nebula-eu.myshopify.com',
    brand: 'nebula',
    country: 'app-eu',
    lang: 'en',
    type: 'app',
  },
];

const config = [
  ...usProd,
  ...ukProd,
  ...caProd,
  ...euProd,
  ...deProd,
  ...esProd,
  ...frProd,
  ...nlProd,
  ...auProd,
  ...jpProd,
  ...itProd,
  ...clProd,
  ...appProd,
  ...neuProd,
];

module.exports = config;
